import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import './hsr-guides.scss';
import { ProfileSparkleBronya } from '../../../modules/hsr/profile/sparkle-bronya';

const HSRGuidesSpaBro: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page hsr-guide spa-bro'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Sparkle, Bronya or Sunday - who is better?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_spabro.webp"
            alt="Sparkle, Bronya or Sunday "
          />
        </div>
        <div className="page-details">
          <h1>Sparkle, Bronya or Sunday - who is better?</h1>
          <h2>
            A comparison between Sparkle, Bronya and Sunday showing who is
            better at supporting Hypercarries!
          </h2>
          <p>
            Last updated: <strong>28/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Sparkle, Bronya or Sunday?" />
        <ProfileSparkleBronya />
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesSpaBro;

export const Head: React.FC = () => (
  <Seo
    title="Sparkle, Bronya or Sunday - who is better? | Honkai: Star Rail | Prydwen Institute"
    description="A comparison between Sparkle, Bronya and Sunday showing who is better at supporting Hypercarries!"
  />
);
