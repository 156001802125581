import React from 'react';
import { HSRCharacter } from '../common/components/hsr-character';

export const ProfileSparkleBronya: React.FC = () => {
  return (
    <>
      <h5>Foreword</h5>
      <div className="employee-container for-nikke">
        <HSRCharacter mode="icon" slug="sparkle" enablePopover />
        <HSRCharacter mode="icon" slug="bronya" enablePopover />
        <HSRCharacter mode="icon" slug="sunday" enablePopover />
      </div>
      <p>
        In this guide we’ll briefly offer a comparison between{' '}
        <strong>
          Sunday, Bronya and Sparkle to highlight their benefits and
          shortcomings.
        </strong>{' '}
        Light Cones are not included in this evaluation and can add further
        boosts to each character, but we have added a section at the end that
        gives additional information about the LC impact.
      </p>
      <ul>
        <li>All characters have 220% CRIT DMG.</li>
        <li>All characters are E0.</li>
        <li>Bronya is the -1 Speed variant.</li>
        <li>Sunday is the -1 Speed variant.</li>
        <li>Sparkle is the Hyperspeed variant.</li>
      </ul>
      <h5>Buffs</h5>
      <div className="profile sparkle-bronya buffs">
        <div className="box">
          <div className="team-container-outside">
            <HSRCharacter mode="icon" slug="sunday" enablePopover />
          </div>
          <h5 className="sunday">Sunday buffs</h5>
          <div className="inner">
            <p>
              <strong>Skill:</strong>
            </p>
            <ul>
              <li>
                Action Advance: <strong>100%</strong>
              </li>
              <li>
                DMG%: <strong>30%</strong> (2 turns)
              </li>
              <li>
                Summon DMG%: <strong>50%</strong> (2 turns)
              </li>
            </ul>
            <p>
              <strong>Ultimate:</strong>
            </p>
            <ul>
              <li>
                Energy: <strong>+40</strong>
              </li>
              <li>
                CRIT DMG%: <strong>78%</strong> (3 turns)
              </li>
            </ul>
            <p>
              <strong>Passive:</strong>
            </p>
            <ul>
              <li>
                CRIT Rate%: <strong>20%</strong>
              </li>
            </ul>
            <p>
              SP Economy: <strong>SP Neutral</strong> (Assumes you get hit once
              every 3 actions to get the missing energy)
            </p>
          </div>
        </div>
        <div className="box">
          <div className="team-container-outside">
            <HSRCharacter mode="icon" slug="bronya" enablePopover />
          </div>
          <h5 className="bronya">Bronya Buffs</h5>
          <div className="inner">
            <p>
              <strong>Skill:</strong>
            </p>
            <ul>
              <li>
                Action Advance: <strong>100%</strong>
              </li>
              <li>
                DMG%: <strong>66%</strong> (1 turn)
              </li>
            </ul>
            <p>
              <strong>Ultimate:</strong>
            </p>
            <ul>
              <li>
                ATK%: <strong>55%</strong> (2 turns)
              </li>
              <li>
                CRIT DMG%: <strong>55%</strong> (2 turns)
              </li>
            </ul>
            <p>
              <strong>Passive:</strong>
            </p>
            <ul>
              <li>
                DMG%: <strong>10%</strong>
              </li>
            </ul>
            <p>
              SP Economy: <strong>SP Negative</strong>
            </p>
          </div>
        </div>
        <div className="box">
          <div className="team-container-outside">
            <HSRCharacter mode="icon" slug="sparkle" enablePopover />
          </div>
          <h5>Sparkle buffs</h5>
          <div className="inner">
            <p>
              <strong>Skill:</strong>
            </p>
            <ul>
              <li>
                Action Advance: <strong>50%</strong>
              </li>
              <li>
                CRIT DMG%: <strong>98%</strong> (1.5 turn)
              </li>
            </ul>
            <p>
              <strong>Ultimate:</strong>
            </p>
            <ul>
              <li>
                DMG%: <strong>30%</strong> (2 turns)
              </li>
            </ul>
            <p>
              <strong>Passive:</strong>
            </p>
            <ul>
              <li>
                ATK%: <strong>15%</strong>
              </li>
              <li>
                Mono Quantum ATK%: <strong>15%</strong>
              </li>
              <li>
                DMG%: <strong>18%</strong>
              </li>
            </ul>
            <p>
              SP Economy: <strong>SP Positive</strong> (Slightly)
            </p>
          </div>
        </div>
      </div>
      <h5>Advance</h5>
      <p>
        When it comes to Advance, Sunday’s is the most powerful and the clear
        winner but only if you’re playing him in a summoner team. Outside of
        summoner teams his Advance is identical in power to Bronya’s with
        Sparkle’s being the weakest. Sunday and Bronya are both effective in the
        -1 and Hyperspeed playstyles but Sparkle is more limited to the
        Hyperspeed playstyle due to her weaker advance.
      </p>
      <ul className="bigger-margin">
        <li>
          <HSRCharacter mode="inline" slug="sunday" enablePopover /> -{' '}
          <strong>100%</strong> +<strong>100%</strong> summon
        </li>
        <li>
          <HSRCharacter mode="inline" slug="bronya" enablePopover /> -{' '}
          <strong>100%</strong>
        </li>
        <li>
          <HSRCharacter mode="inline" slug="sparkle" enablePopover /> -{' '}
          <strong>50%</strong>
        </li>
      </ul>
      <h5>Crit DMG</h5>
      <p>
        Sparkle and Sunday both have strong CRIT DMG buffs they can keep active
        at all times on their carry with Sparkle having a slight edge in most
        cases. Bronya’s CRIT DMG boost is weaker and has poor uptime due to its
        2 turn duration, being tied to her allies turns and triggering from her
        Ultimate. Sunday does not have this issue as his Ultimate is based on
        his own turns with a longer duration.
      </p>
      <ul className="bigger-margin">
        <li>
          <HSRCharacter mode="inline" slug="sunday" enablePopover /> -{' '}
          <strong>78%</strong> (Perma uptime)
        </li>
        <li>
          <HSRCharacter mode="inline" slug="bronya" enablePopover /> -{' '}
          <strong>55%</strong> (Poor uptime)
        </li>
        <li>
          <HSRCharacter mode="inline" slug="sparkle" enablePopover /> -{' '}
          <strong>98%</strong> (Perma uptime)
        </li>
      </ul>
      <h5>Crit Rate</h5>
      <p>
        Sunday is the only one of the three with a CRIT RATE buff and it’s a
        massive one applying in any team. This buff more than makes up for his
        lack of ATK% buffs and for most characters is even preferable.
      </p>
      <ul className="bigger-margin">
        <li>
          <HSRCharacter mode="inline" slug="sunday" enablePopover /> -{' '}
          <strong>20%</strong> (Perma uptime)
        </li>
        <li>
          <HSRCharacter mode="inline" slug="bronya" enablePopover /> -{' '}
          <strong>0%</strong>
        </li>
        <li>
          <HSRCharacter mode="inline" slug="sparkle" enablePopover /> -{' '}
          <strong>0%</strong>
        </li>
      </ul>
      <h5>DMG%</h5>
      <p>
        Bronya has the strongest DMG% boost but it isn’t always fully active due
        to her Skill's 1 turn duration clashing with her -1 Speed playstyle,
        resulting in half the carries attacks being unbuffed. Sparkle can
        maintain her buff by Ulting the carry on their turn and Sunday’s buff
        lasts 3 turns, ensuring it’s always active. Bronya is most powerful if
        you can align powerful cooldowns during her short window, Sparkle is
        most consistent and Sunday has the weakest DMG% outside of summoner
        teams in which he smashes everyone.
      </p>
      <ul className="bigger-margin">
        <li>
          <HSRCharacter mode="inline" slug="sunday" enablePopover /> -{' '}
          <strong>30%</strong> (<strong>80%</strong> including Summon) (Perma
          uptime)
        </li>
        <li>
          <HSRCharacter mode="inline" slug="bronya" enablePopover /> -{' '}
          <strong>66%</strong> (Half uptime or Less) + <strong>10%</strong>{' '}
          (Perma uptime)
        </li>
        <li>
          <HSRCharacter mode="inline" slug="sparkle" enablePopover /> -{' '}
          <strong>48%</strong> (Perma uptime)
        </li>
      </ul>
      <h5>ATK%</h5>
      <p>
        Similar to her CRIT DMG% buff, Bronya’s ATK% buff suffers the same fate,
        being tied to her Ultimate which results in poor uptime. Sparkle
        provides a weak ATK% buff in rainbow teams but a much stronger one in
        Mono Quantum teams. Sunday has no ATK% buff but more than makes up for
        this in other areas.
      </p>
      <ul className="bigger-margin">
        <li>
          <HSRCharacter mode="inline" slug="sunday" enablePopover /> -{' '}
          <strong>0%</strong>
        </li>
        <li>
          <HSRCharacter mode="inline" slug="bronya" enablePopover /> -{' '}
          <strong>55%</strong> (Poor Uptime)
        </li>
        <li>
          <HSRCharacter mode="inline" slug="sparkle" enablePopover /> -{' '}
          <strong>15%</strong> (Perma uptime) + <strong>30%</strong> Mono
          Quantum Only (Perma uptime)
        </li>
      </ul>
      <h5>Energy Regen</h5>
      <p>
        Also unique to Sunday is his Energy Regen, adding even more value to his
        kit where he was already competing nicely.
      </p>
      <ul className="bigger-margin">
        <li>
          <HSRCharacter mode="inline" slug="sunday" enablePopover /> -{' '}
          <strong>40</strong> (Every 3 actions)
        </li>
        <li>
          <HSRCharacter mode="inline" slug="bronya" enablePopover /> -{' '}
          <strong>0</strong>
        </li>
        <li>
          <HSRCharacter mode="inline" slug="sparkle" enablePopover /> -{' '}
          <strong>0</strong>
        </li>
      </ul>
      <h5>Skill Points</h5>
      <p>
        Perhaps most important part of the comparison. Sparkle is slightly SP
        Positive, but offset by the fact she can’t fully play within the -1
        playstyle to take advantage of it due to her 50% Advance. Bronya is very
        SP negative making the -1 playstyle challenging but doable in a limited
        amount of teams. Sunday is fully capable of the -1 playstyle and is SP
        neutral (assuming Sunday gets hit once every 3 actions to get the
        missing energy - which in the current AoE heavy meta, will happen even
        more often), unlocking the playstyle for vastly more teams than Bronya
        allowed.
      </p>
      <ul className="bigger-margin">
        <li>
          <HSRCharacter mode="inline" slug="sunday" enablePopover /> -{' '}
          <strong>SP Neutral</strong>
        </li>
        <li>
          <HSRCharacter mode="inline" slug="bronya" enablePopover /> -{' '}
          <strong>SP Negative</strong>
        </li>
        <li>
          <HSRCharacter mode="inline" slug="sparkle" enablePopover /> -{' '}
          <strong>SP Positive (Slightly)</strong>
        </li>
      </ul>
      <h5>Comparison</h5>
      <ul>
        <li>
          <HSRCharacter mode="inline" slug="sunday" enablePopover /> vs{' '}
          <HSRCharacter mode="inline" slug="bronya" enablePopover /> - Sunday is
          the clear winner, offering many of the same things as Bronya on top of
          some absurdly potent extras, all while consuming no Skill Points
          compared to her (Bronya is the Skill Point abyss).
        </li>
        <ul>
          <li>
            It must be noted that as a standard character, pulling Bronya’s
            Eidolons is only a matter of time for long time players. With them,
            Bronya can regain some ground but Sunday’s advantage is that big,
            that he wont be overcome in all but the most specific situations.
          </li>
        </ul>
        <li>
          <HSRCharacter mode="inline" slug="sunday" enablePopover /> vs{' '}
          <HSRCharacter mode="inline" slug="sparkle" enablePopover /> - In teams
          that prefer a Hyperspeed advancement, Sparkle can more or less hold
          her own against Sunday. Sparkle also provides very different access to
          Skill Points, offering plenty SP up-front, but consuming them herself
          - compared to Sunday who offers none and essentially uses none
          (without his Signature). This can be valuable for teams that need
          Skill Points at the start of fights. While Sparkle has places where
          she thrives, Sunday without a doubt holds the top spot in more teams -
          he is the clear winner in any summoner team, energy hungry team or
          team who wants to take advantage of the -1 playstyle.
        </li>
      </ul>
      <h5>Signatures</h5>
      <p>
        When considering each character with their signature Light Cones, all of
        them gain a boost to their capabilities. Here are the changes they make
        - if you have access to these make sure to factor them into your
        evaluation of the characters.
      </p>
      <ul className="bigger-margin">
        <li>
          <HSRCharacter mode="inline" slug="sunday" enablePopover /> - Adds a
          45% DMG boost to Sunday’s carry permanently and also offers him a
          constant stream of Skill Points as he uses his Skill and Ultimate.
          This Cone allows Sunday to match Sparkle’s status as Skill Point
          positive, improving his flexibility further while also bolstering his
          DMG% bonuses which outside of summoner teams is low.
        </li>
        <li>
          <HSRCharacter mode="inline" slug="bronya" enablePopover /> - Softens
          Bronya’s Skill Point requirements slightly and adds a further 30% DMG
          boost to her skill, boosting it from 66% to 96%. This Cone helps make
          SP more manageable and bolsters her buffs but doesn’t help with her
          uptime or bringing her any closer to her competition - as a standard
          Cone her competition can also use it to great effect.
        </li>
        <li>
          <HSRCharacter mode="inline" slug="sparkle" enablePopover /> - Grants
          the wearer 32% CRIT DMG boosting their scaling abilities and offers
          the entire team a permanent 10% CRIT RATE and 28% CRIT DMG boost. With
          her Signature equipped, Sparkle’s CRIT buffing capabilities are
          seriously amped up giving her by far the most CRIT DMG and a bit of
          CRIT RATE to gain some ground on Sunday.
        </li>
      </ul>
      <h5>TL;DR</h5>
      <p>
        Who is better between the three comes down to the damage dealer of your
        choice and the specific setup you're using,{' '}
        <strong>
          but in most cases, Sunday will be the primary choice in Hypercarry
          teams moving forward
        </strong>{' '}
        - with or without his Signature, but without you will have to rely on
        getting hit from time to time to achieve the 3-turn rotation.
      </p>
    </>
  );
};
